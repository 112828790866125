export const Api_url = {

    GetUserDetail: '/beta_user/auth/me',
    Authentication: '/continue',
    UpdateUserGoal: '/certified_user_skill/update_goal_test',

    SendUserSkill: '/new_entry_test_v2',
    GenerateMCQuestions: '/generate',

    SaveUserResponse: '/save_user_response',
    GetOurLearnersWorkAt: '/user_placements',

    UserQuizAnalysis: '/analysis',

    CreateCertificateId: '/create',
    GetCertificateLink: '/file',

    ClaimCertificate: '/certified_user_skill/claim_available_certificate',

    GetLeaderBoardData: '/certified_skill/leaderboard',
    LeaderboardTotalRecords: '/certified_skill/leaderboard/total_records',
    GetUserFullDetails: '/certified_user_skill',

    SuggestedCourseBundle: '/certified_user_skill_quiz/suggested_bundle_test',
    CourseSyllabus: '/certified_bundle_course/syllabus_test',
    CreateBundle: '/certified_bundle/new',
    RemoveFromBundle: '/certified_bundle_course/remove',
    AddToBundle: '/certified_bundle_course/add_test',
    ClaimCurrentCourse: '/certified_bundle_course/mark_claimed_test',

    CreatePaymentOrder: '/create_v2_test',
    GetProductDetails: '/certified_product',

    GetDashBoardData: '/dashboard/data_test',

    GetWhatsAppToken: '/certified_user_skill/wa_token_test',

    CareerGoals: '/career_goals/add',

    MarkPersonalisedPaid: '/personalized_course/mark_as_paid',

    ReferralId: '/certified_referral/new',

    TransactionHistory: '/transaction/history',

    RegenerateBundle: '/certified_user_skill_quiz/change_suggested_bundle',

    RemoveOrReplace: '/certified_bundle_course/replace',

    UserCartData: '/user_cart',

    UpdateUserCartData: '/user_cart/update'
}