import { createSlice } from "@reduxjs/toolkit"
import { Mixpanel } from "../config/Mixpanel"
import constants from "../utilities/constants"

const initialState = {
    threeCertificates: false, 
    fiveCertificates: false, 
    sevenCertificates: false, 
    proMembershipSelected: false, 
    personalisedSelected: false, 
    fourWeekPlacementFeature: false,
    price: {},
    utm_source: ''
}

const addOnSlice = createSlice({
    name: 'addOn-selected',
    initialState,
    reducers: {
        setThreeCertificates: (state, action) => {
            state.threeCertificates = action.payload
        },
        setFiveCertificates: (state, action) => {
            state.fiveCertificates = action.payload
        },
        setSevenCertificates: (state, action) => {
            state.sevenCertificates = action.payload
        },
        handleBundles: (state, action) => {
            if (action.payload === 1){
                if(state.threeCertificates){
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_THREE_CERTIFICATES_REMOVED)
                    state.threeCertificates = false
                }else{
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_THREE_CERTIFICATES_SELECTED)
                    state.threeCertificates = true
                }    
                state.fiveCertificates = false
                state.sevenCertificates = false
                
            }else if (action.payload === 2){
                if(state.fiveCertificates){
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_FIVE_CERTIFICATES_REMOVED)
                    state.fiveCertificates = false
                }else{
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_FIVE_CERTIFICATES_SELECTED)
                    state.fiveCertificates = true
                }
                state.threeCertificates = false
                state.sevenCertificates = false
            }else {
                if(state.sevenCertificates){
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_SEVEN_CERTIFICATES_REMOVED)
                    state.sevenCertificates = false
                }else{
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_SEVEN_CERTIFICATES_SELECTED)
                    state.sevenCertificates = true
                }
                state.threeCertificates = false
                state.fiveCertificates = false
            }
        },
        setPrice: (state, action) => {
            state.price = action.payload
        },
        setAnalysisPageUtmSource: (state, action) => {
            state.utm_source = action.payload
        },
        setProMembershipSelected: (state) => {
            if (state.proMembershipSelected){
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PRO_MEMBERSHIP_COURSE_REMOVED)
            }else{
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PRO_MEMBERSHIP_COURSE_SELECTED)
            }
            state.proMembershipSelected = !state.proMembershipSelected
        },
        setFourWeekPlacementFeature: (state) => {
            if (state.fourWeekPlacementFeature){
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_FOUR_WEEK_PLACEMENT_REMOVED)
            }else{
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_FOUR_WEEK_PLACEMENT_SELECTED)
            }
            state.fourWeekPlacementFeature = !state.fourWeekPlacementFeature
        },
        setPersonalisedSelected: (state) => {
            if (state.personalisedSelected){
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PERSONALISED_COURSE_REMOVED)
            }else {
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PERSONALISED_COURSE_SELECTED)
            }
            state.personalisedSelected = !state.personalisedSelected
        },
        resetAddOnDetails: () => initialState
    }
})

export const { handleBundles, setPrice, setAnalysisPageUtmSource, setProMembershipSelected, setFourWeekPlacementFeature, setPersonalisedSelected, setThreeCertificates, setFiveCertificates, setSevenCertificates, resetAddOnDetails } = addOnSlice.actions
export const selectAddOns = (state) => state.addOns
export const selectPrice = (state) => state.addOns.price
export const selectAnalysisPageUtmSource = (state) => state.addOns.utm_source
export default addOnSlice.reducer