import { faArrowRight, faCheck, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import SeoAdditionalFeatures from './SeoAdditionalFeatures'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { useSelector } from 'react-redux'
import { selectSelectedItems } from '../../redux/searchSlice'
import useWindowSize from '../../hooks/useWindowSize'
import { selectAddOns } from '../../redux/addOnSlice'

const Pricingcard = ({ score, skill_name, price, featurePrice, handleNavigate, normalFeaturePrice }) => {

    const selectedSkills = useSelector(selectSelectedItems)
    const addOns = useSelector(selectAddOns)

    const customBundleLength = selectedSkills?.length
    const { threeCertificates, fiveCertificates, sevenCertificates, proMembershipSelected, personalisedSelected, fourWeekPlacementFeature } = addOns
    const customBundleSelected = customBundleLength > 0
    const failedCandidate = score < 40

    const [ showFeatures, setShowFeatures ] = useState(false)

    const { showLoader } = useAddonContext()

    const { width } = useWindowSize()

    const addOnSelected = threeCertificates || proMembershipSelected || personalisedSelected || fiveCertificates || sevenCertificates || fourWeekPlacementFeature || customBundleSelected

    useEffect(() => {
        if (addOnSelected){
            setShowFeatures(true)
        }
    }, [addOnSelected, setShowFeatures])

    const showAdditionalFeatures = useMemo(() => {
        return showFeatures || threeCertificates || proMembershipSelected || personalisedSelected || fiveCertificates || sevenCertificates || fourWeekPlacementFeature || customBundleSelected
    }, [customBundleSelected, fiveCertificates, fourWeekPlacementFeature, personalisedSelected, proMembershipSelected, sevenCertificates, showFeatures, threeCertificates])

    return (
        <div className={`bg-secondary_background mt-6 p-3 md:p-4 rounded-xl`}>
            {failedCandidate ? <h3 className={`${width <= 360 ? 'text-lg' : 'text-xl'} md:text-[28px] leading-[130%]`}>Unlock Your Certificate Now & Get A <span className='font-bold text-span_color'>Complimentary Course </span>To Be A <span className='font-bold'>Top 10% {skill_name} Candidate</span></h3> : score >= 80 ? <h3 className={`${width <= 360 ? 'text-lg' : 'text-xl'} md:text-[28px] leading-[130%]`}>Unlock Instant Certificate To Prove You’re In The Top <span className='font-bold text-span_color'>10% Of {skill_name} Candidates</span></h3> : <h3 className={`${width <= 360 ? 'text-lg' : 'text-xl'} md:text-[28px] leading-[130%]`}>Unlock Instant Certificate To View Score & Prove You’re In The Top <span className='font-bold text-span_color'>10% Of {skill_name} Candidates</span></h3>}
            {failedCandidate ? <div className='text-left bg-bg-1 rounded-xl mt-4 flex flex-col'>
                <img src="https://ik.imagekit.io/238r9emirjj/new-certificate-changes/Group%2022086%20(2).png?updatedAt=1732280514090" alt="" className='px-0.5 pt-1.5' />
                <FontAwesomeIcon icon={faPlus} />
                <div className='px-2 mt-1 mb-2'>
                    <div className='bg-bg-2 p-2 text-xs w-full rounded-lg flex items-center gap-2'>
                        <img src="https://ik.imagekit.io/238r9emirjj/new-certificate-changes/4379787%203.svg?updatedAt=1732278221306" width={24} height={32} alt="" className='' />
                        <p className='flex-1'><span className='font-semibold'>Personalised Course</span> Included.<FontAwesomeIcon icon={faCheck} className='text-check-mark text-lg' /></p>
                        <div className='text-center'>
                            <p className='text-xxs text-white/50'>Worth</p>
                            <p className='text-sm font-bold text-check-mark'>₹399</p>
                        </div>
                    </div>
                </div>
                <p className='text-xxs text-white/70 text-center mb-3'><span className='text-secondary-text'>8/10</span> CV’s With Certificate + Course Got Hired</p>
            </div> : <div className='w-full mt-4 mx-auto' style={{
                boxShadow: '0px 0px 12px 0px #FFCC17'
            }}>
                <img src="https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group%209227.webp?updatedAt=1720514693587" alt="" />
            </div>}
            {!failedCandidate && <p className='text-[13px] md:text-xl mt-1 font-normal'><span className='text-[#2BBD13] font-medium'>8/10</span> CV’s With This Certificate Got Hired</p>}
            <div className='bg-[#7FC241] mt-4 md:mt-5 w-full cursor-pointer text-black p-2 rounded-lg btn--glow' onClick={handleNavigate}>
                <div className='flex justify-center items-center'>
                    <p className={`${width <= 320 ? 'text-base' : 'text-lg'} font-medium`}>{showLoader ? <ClipLoader color='black' size={20} /> : 'Get My Instant Certificate'}</p>
                    {!showLoader && <FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 arrow-move'/>}
                </div>
                <p className='text-xxs'>For: <span className='text-xs font-medium'>&#8377;{price?.price + featurePrice()}</span> <span className='text-danger'>&#8377;<span className='line-through'>{`${price?.original_price + normalFeaturePrice()}`}</span></span> <span className=''>{addOnSelected && '(Including Addons)'}</span></p>
            </div>
            {showAdditionalFeatures ? <SeoAdditionalFeatures
                skill_name={skill_name}
            /> : <div className='mt-4' onClick={() => setShowFeatures(true)}>
                <p className='text-[13.5px] md:text-xl font-medium text-secondary-text cursor-pointer'>Add Popular Benefits (Optional) <FontAwesomeIcon icon={faChevronDown} className='text-secondary-text text-lg font-bold ml-1 down' /></p>
            </div>}
        </div>
    )
}

export default Pricingcard