
const BottomPricingcard = ({ handleNavigate }) => {

    return (
        <div className='bg-secondary_background flex flex-col items-center border-2 border-[#7FC241] rounded-xl p-3 mt-6'>
            <div className='flex flex-col items-center md:flex-row'>
                <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Businessman_personal_goal_with_gold_trophy_and_dartboard_1.png?updatedAt=1688111367452" alt="" width={213.6} height={178} />
                <h2 className='text-[28px] font-medium leading-[130%] mb-3'><span className='text-span_color font-bold'>Unlock Certificate</span> And Upgrade Your Career</h2>
            </div>
            <div className='w-[1px] h-[1px]'></div>
            <div className='w-full md:w-[80%]'>
                <div className='font-medium flex justify-center items-center mx-auto w-full md:w-3/4 md:mt-3 rounded-xl btn--glow text-black bg-secondary-text cursor-pointer py-3 mb-3 mt-1' onClick={handleNavigate}>
                    Get My Instant Certificate
                </div>
                <p className='text-xs'>Helps you get hired upto <img src='https://ik.imagekit.io/238r9emirjj/new-certificate-changes/Group.svg?updatedAt=1732337342192' width={18} height={9.7} alt='' className='image-inline'/> <span className='text-secondary-text'>47% faster</span></p>
            </div>
        </div>
    )
}

export default BottomPricingcard