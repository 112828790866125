import React from 'react'
import Slider from 'react-slick'
import { linkdInCertificateList, newLinkedInCertificateList } from '../../utilities/constants'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from '../../hooks/useWindowSize'

const settings = {
    dots: true,
    slidesToShow: 1,
    rows: 1,
    speed: 10000,
    autoplay: true,
    autoplaySpeed: 2000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    arrows: true,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                infinite: true,
                dots: true,
                arrows: false,
                swipeToSlide: false
            }
        }
    ]
}

const LinkdInUsers = () => {

    const [ showMore, setShowMore ] = useState(Array(linkdInCertificateList.length).fill(false))
    const [ showComments, setShowComments ] = useState(Array(linkdInCertificateList.length).fill(false))

    const { width } = useWindowSize()

    const handleShowMore = (index) => {
        const tempArray = [...showMore]
        tempArray[index] = !showMore[index]
        setShowMore(tempArray)
    }

    const handleComments = (index) => {
        const tempArray = [...showComments]
        tempArray[index] = !showComments[index]
        setShowComments(tempArray)
    }
    
    return (
        <div className='w-full'>
            <div className='w-full'>
                <Slider {...settings}>
                    {newLinkedInCertificateList.map((item, index) => (<div className='' key={index}>
                        <div className={`bg-[#2D4F71] rounded-xl p-4 w-[99%] ${width <= 320 ? 'min-h-[400px]' : width <= 360 ? 'min-h-[420px]' : width <= 420 ? 'min-h-[460px]' : width <=580 ? 'min-h-[432px]' :'min-h-[594px]'} flex flex-col`}>
                            <div className='flex-1 flex flex-col justify-center'>
                                <div className={`flex justify-between items-center gap-2`}>
                                    <img src={item?.profile_image} width={50} height={50} className='w-[50px] h-[50`] rounded-full object-cover flex-shrink-0' alt="" />
                                    <div className='flex flex-col flex-1'>
                                        <p className='text-sm font-bold line-clamp-2'>{item.name}</p>
                                        <p className='text-xs font-medium text-white/50 line-clamp-1'>{item.designation}</p>
                                    </div>
                                    <div className='w-8 h-8 flex-shrink-0'>
                                        <Link to={item.post_link}>
                                            <img src='https://ik.imagekit.io/238r9emirjj/LinkdinUsers/linkedin%201.png?updatedAt=1698817296779' className='w-full h-full object-cover' alt="" />
                                        </Link>
                                    </div>
                                </div>

                                <div className='mt-1'>
                                    <div className={`${showMore[index] ? '' : 'line-clamp-3'}`}>
                                        {item.description.split('\n').map((line, lineIndex) => (
                                            <div key={lineIndex} className='mt-2 text-xs' dangerouslySetInnerHTML={{ __html: line}}/>
                                        ))}
                                    </div>
                                    <p onClick={() => handleShowMore(index)} className={`text-[#40DA90] text-xs font-medium underline cursor-pointer mt-0.5`}>{showMore[index] ? 'Show Less' : 'Show More'}</p>
                                </div>
                                <div className='mt-3'>
                                    <img src={item.certificate_image} className="rounded-xl" alt="" />
                                </div>
                                <div className='flex justify-between mt-2'>
                                    <div className='flex items-center gap-1'>
                                        {item?.icon_image}
                                        <p className='text-xs font-medium'>{item.likes}</p>
                                    </div>
                                    {item.comments.length > 0 && <div className='text-xs cursor-pointer underline' onClick={() => handleComments(index)}>
                                        {item.comments.length} {item.comments.length > 1 ? 'comments ' : 'comment '}
                                        <FontAwesomeIcon icon={showComments[index]  ? faChevronUp : faChevronDown} className='mt-1 underline' />
                                    </div>}
                                </div>
                                {showComments[index] && <>
                                    <div className={`border-t border-[#3B6EA0] mt-3`}/>
                                    <div className={`w-full`}>
                                        {item.comments.map((comment) => (
                                            <div key={comment.id} className='flex gap-2 pt-2 w-full'>
                                                <img src={comment.user_image} className='w-10 h-10 rounded-full' alt="" />
                                                <div className='flex flex-col rounded-b-lg rounded-tr-lg bg-bg-1 flex-1 p-2'>
                                                    <div className='leading-[14px]'>
                                                        <p className='text-[11px] font-medium'>{comment.user_name} <span className='text-white/50'>{comment.gender}</span></p>
                                                        <p className='text-[9px] font-medium line-clamp-1 text-white/50'>{comment.designation}</p>
                                                    </div>
                                                    <div className={`text-[11px] ${index === 6 ? 'mt-1 xs:mt-2' : 'mt-2'}`} dangerouslySetInnerHTML={{ __html: comment.description }}/>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>))}
                </Slider>
            </div>
        </div>
    )
}

export default LinkdInUsers
// {