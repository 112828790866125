import { certifiedFaq } from '../../utilities/constants'
import FaqAccordian from './FaqAccordian'

const CertifiedFAQSection = ({ sentinelRef }) => {
    return (
        <div className='flex flex-col rounded-xl gap-[1px] bg-[#5D8AB7]'>
            {certifiedFaq.map(({ question, answer }, index) => {
                return index === 2 ? <FaqAccordian ref={sentinelRef} title={question} key={index} index={index} length={certifiedFaq?.length} content={answer}/> : <FaqAccordian title={question} key={index} index={index} length={certifiedFaq?.length} content={answer}/>
            })}
        </div>
    )
}

export default CertifiedFAQSection