import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotificationBar } from '../../redux/otherDetailsSlice'

const NotificationBar = () => {

    const [redPercentage, setRedPercentage] = useState(0)

    const dispatch = useDispatch()

    useEffect(() => {
        const interval = setInterval(() => {
        setRedPercentage(prev => {
            if (prev < 100) {
                return prev + 1
            } else {
                dispatch(setNotificationBar(false))
                clearInterval(interval)
                return 100
            }
        })}, 40)

        return () => clearInterval(interval)
    }, [dispatch])

    return (
        <div className='fixed max-w-2xl z-[90] mx-auto top-0 bg-bg-3 w-full'>
            <div className='p-3 flex items-center gap-2'>
                <img src="https://ik.imagekit.io/238r9emirjj/new-certificate-changes/5740394%201.svg?updatedAt=1733905959549" width={34} height={34} alt="" />
                <p className='text-sm text-white'>Congrats! You’ve unlocked an <span className='font-bold text-span_color'>extra 5% discount</span> 🎉 Add one more to double it to <span className='font-bold'>10%!</span></p>
            </div>
            <div className="w-full h-1" style={{
                background: `linear-gradient(270deg, #7FC241 ${100 - redPercentage}%, #26588B ${100 - redPercentage}%)`,
                transition: 'background 30ms linear'
            }}/>
        </div>
    )
}

export default NotificationBar