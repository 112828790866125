import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const GreenTickMark = () => {
    return (
        <div className='w-4 h-4 rounded-full bg-secondary-text flex items-center justify-center'>
            <FontAwesomeIcon icon={faCheck} className='text-xs' />
        </div>
    )
}

export default GreenTickMark