import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectPriceChart } from '../../redux/priceChartSlice'
import { nineNintyNine } from '.'
import { selectMCQDetails } from '../../redux/mcqSlice'
import { setPossibleSubjectModal, setShowCategoryModal, setShowCourseModal, setShowPlacementModal, setShowRelatedSubjectsModal, setVideoIndex } from '../../redux/otherDetailsSlice'
import { resetSelectedItems, selectSelectedItems } from '../../redux/searchSlice'
import { handleBundles, selectAddOns, setFourWeekPlacementFeature, setPersonalisedSelected, setProMembershipSelected } from '../../redux/addOnSlice'

const SeoAdditionalFeatures = ({ skill_name }) => {

    const priceChart = useSelector(selectPriceChart)
    const quizDetails = useSelector(selectMCQDetails)
    const selectedSkills = useSelector(selectSelectedItems)
    const addOns = useSelector(selectAddOns)

    const score = quizDetails?.quiz_score
    const { threeCertificates, fiveCertificates, sevenCertificates, proMembershipSelected, personalisedSelected, fourWeekPlacementFeature } = addOns
    
    const customBundleLength = selectedSkills?.length
    const customBundleSelected = customBundleLength > 0
    const skillIsBusinessManagement = nineNintyNine.includes(skill_name?.toLowerCase())

    const { skillIsNotSeo, setRating, setStudent, setCourseName } = useAddonContext()

    const dispatch = useDispatch()

    const handlePersonaliseModal = (e) => {
        e.stopPropagation()
        setRating(5)
        setStudent(Math.floor((Math.random() * 1200) + 301))
        setCourseName(skill_name)
        dispatch(setShowCourseModal(true))
    }

    const handleCategoryModal = (e) => {
        e.stopPropagation()
        dispatch(setShowCategoryModal(true))
    }

    const handleRelatedSubjectsModal = (e, number, subtitle) => {
        e.stopPropagation()
        dispatch(setShowRelatedSubjectsModal(true))
        dispatch(setPossibleSubjectModal({
            subjectNumber: number,
            subtitle
        }))
    }

    const handleSelectionOfBundles = (number) => {
        dispatch(handleBundles(number))
    }

    return (
        <div className='text-[#7FC241] mt-4'>
            <p className='text-sm md:text-xl font-medium mb-2'>Add Popular Benefits (Optional)</p>
            <div className='mt-2 flex flex-col gap-2.5'>
                {skillIsNotSeo && !sevenCertificates && !fiveCertificates && <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${threeCertificates ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => handleSelectionOfBundles(1)}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${threeCertificates && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                            <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>{priceChart[2]?.product_description} <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={(e) => handleRelatedSubjectsModal(e, 'Any 3', 'Select Any 3 Later from this List for an 48% Chance to Stand Out.')}/></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{skillIsBusinessManagement ? priceChart[37].price : priceChart[2].price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{skillIsBusinessManagement ? priceChart[37].original_price : priceChart[2].original_price}</p>
                    </div>
                </div>}
                {fiveCertificates && <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${fiveCertificates ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => handleSelectionOfBundles(2)}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${fiveCertificates && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                            <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>{priceChart[10]?.product_description} <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={(e) => handleRelatedSubjectsModal(e, 'Any 5', 'Select Any 5 Later from this List for an 80% Chance to Stand Out.')}/></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{skillIsBusinessManagement ? priceChart[38].price : priceChart[10].price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{skillIsBusinessManagement ? priceChart[38].original_price : priceChart[10].original_price}</p>
                    </div>
                </div>}
                {sevenCertificates && <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${sevenCertificates ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => handleSelectionOfBundles(3)}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${sevenCertificates && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                            <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>{priceChart[11]?.product_description} <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={(e) => handleRelatedSubjectsModal(e, 'Any 7', 'Select Any 7 Later from this List for 2X more likely to be noticed by top MNCs & unicorns.')}/></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{skillIsBusinessManagement ? priceChart[39].price : priceChart[11].price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{skillIsBusinessManagement ? priceChart[39].original_price : priceChart[11].original_price}</p>
                    </div>
                </div>}
                {customBundleSelected && <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${customBundleSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => dispatch(resetSelectedItems())}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${customBundleSelected && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                        <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>{customBundleLength} More Certificates in Subjects of Your Choice</p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{(Math.round((customBundleLength * (199) * (1 - (Math.min(customBundleLength * 5 /100, 0.5))))))}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{customBundleLength * (199)}</p>
                    </div>
                </div>}
                {score >= 40 && <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer gap-2 rounded-lg transform transition-transform ${personalisedSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => dispatch(setPersonalisedSelected())}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${personalisedSelected && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                            <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>Personalised {skill_name} Course Built For You Basis Your Quiz <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={handlePersonaliseModal} /></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{priceChart[4]?.price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{priceChart[4]?.original_price}</p>
                    </div>
                </div>}
                <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${proMembershipSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => dispatch(setProMembershipSelected())}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center border-[#7FC241] shadow-boxShadow ${proMembershipSelected && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                        <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>Unlimited Access to {skill_name} & 1240+ More Certified Courses <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={handleCategoryModal} /></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{priceChart[3]?.price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{priceChart[3]?.original_price}</p>
                    </div>
                </div>
                {fourWeekPlacementFeature && <div className={`bg-bg-1 flex justify-between items-center py-3 px-4 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${fourWeekPlacementFeature ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => dispatch(setFourWeekPlacementFeature())}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center border-[#7FC241] shadow-boxShadow ${fourWeekPlacementFeature && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-bg-1 text-lg'/>
                        </div>
                        <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>Join our 4 Week Placement Training & Get Hired 3X Faster <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={(e) => {
                                e.stopPropagation()
                                dispatch(setVideoIndex(100))
                                dispatch(setShowPlacementModal(true))
                            }} /></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{priceChart[34]?.price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{priceChart[34]?.original_price}</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default SeoAdditionalFeatures