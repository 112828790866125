import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDayTimer } from '../redux/dayTimerSlice'
import { addToBundle, removeFromBundle, selectCertificateBundleList, selectNewCertificateList, selectNewCourseList, selectRemoveFromCertificateList, selectSuggestedCourseList, selectWhyDoesThisWork, setNewUserCourseList, setShowBundleCart, setShowDraftSuggestedBundle, setShowWhyDoesThisWork, setUserNewCertificateList, setUserRemoveFromCertificateList, userCertifiedOrPersonalisedBundle } from '../redux/postPaymentSlice'
import { toast } from 'react-toastify'
import { selectTypeformData } from '../redux/mcqSlice'
import { Mixpanel } from '../config/Mixpanel'
import constants from '../utilities/constants'
import { ClipLoader } from 'react-spinners'

const NewTimeStrip = () => {

    const { hours, minutes, seconds } = useSelector(selectDayTimer)
    const newCertificateList = useSelector(selectNewCertificateList)
    const typeformData = useSelector(selectTypeformData)
    const certificateBundleDetails = useSelector(selectCertificateBundleList)
    const userRemoveFromCertificateList = useSelector(selectRemoveFromCertificateList)
    const newCourseList = useSelector(selectNewCourseList)
    const suggestedCourseDetails = useSelector(selectSuggestedCourseList)
    const showWhyDoesThisWork = useSelector(selectWhyDoesThisWork)

    const [ loading, setLoading ] = useState(false)

    const dispatch = useDispatch()

    const handleSuggestedBundle = async () => {
        if(showWhyDoesThisWork){
            dispatch(setShowWhyDoesThisWork(false))
            dispatch(setShowBundleCart(true))
            dispatch(setShowDraftSuggestedBundle(false))
            return
        }
        if (loading) {
            return
        }
        setLoading(true)
        Mixpanel.track(constants.Mixpanel.FINALISED_BUNDLE_SCREEN_GET_THIS_PATH_BUTTON_CLICKED)
        try {
            if (newCertificateList && newCertificateList.length > 0) {
                if (certificateBundleDetails.id){
                    const certificateList = newCertificateList?.map((item) => item?.course_name)
                    await dispatch(addToBundle({
                        certified_bundle_id: certificateBundleDetails?.id,
                        course_name_list: certificateList,
                        type: 'custom_certified_bundle'
                    })).unwrap()
                }else{
                    await dispatch(userCertifiedOrPersonalisedBundle({ course_list: newCertificateList, bundle_type: 'custom_certified_bundle', certified_user_skill_id: typeformData?.id })).unwrap()
                }
                dispatch(setUserNewCertificateList([]))
            }
            if (userRemoveFromCertificateList && userRemoveFromCertificateList.length > 0){
                await dispatch(removeFromBundle({ certified_bundle_id: certificateBundleDetails?.id, certified_bundle_course_id: userRemoveFromCertificateList, type: 'custom_certified_bundle' })).unwrap()
                dispatch(setUserRemoveFromCertificateList([]))
            }
            if (newCourseList && newCourseList.length > 0){
                await dispatch(addToBundle({
                    certified_bundle_id: suggestedCourseDetails?.id,
                    course_name_list: newCourseList
                })).unwrap()
                dispatch(setNewUserCourseList([]))
            }
            dispatch(setShowDraftSuggestedBundle(false))
            dispatch(setShowBundleCart(true))
        } catch (error) {
            console.log(error.messaqge)
            toast.error('Something Went Wrong, Try Again!')
        }finally {
            setLoading(false)
        }
    }

    return (
        <div className='bg-[#FF4646]/20 px-2 md:px-4 py-2 flex justify-between items-center gap-2 cursor-pointer' onClick={handleSuggestedBundle}>
            <p className='text-white text-xxs'>Scholarship Ends In: <span className='text-xs font-extrabold text-danger'>{hours}H : {minutes}M : {seconds}S</span></p>
            {loading ? <ClipLoader size={20} color='#80C242' /> : <p className='text-sm text-secondary-text font-semibold'><span className='underline'>View Cart</span><FontAwesomeIcon icon={faArrowRight} className='arrow-move ml-2' /></p>}
        </div>
    )
}

export default NewTimeStrip