import { REK, ENV } from './config/api_config';

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import thunkMiddleware from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'

import authenticationReducer from './redux/authenticationSlice'
import mcqReducer from './redux/mcqSlice'
import otherDetailsReducer from './redux/otherDetailsSlice'
import answersReducer from './redux/answersSlice'
import multiSkillSelectionReducer from './redux/multiSkillSelectionSlice'
import personaliseCourseSyllabusReducer from './redux/personaliseCourseSyllabusSlice'
import personalisedCourseDetailsReducer from './redux/personalisedCourseDetailsSlice'
import postPaymentReducer from './redux/postPaymentSlice'
import influencersDataReducer from './redux/influencersDataSlice'
import priceChartReducer from './redux/priceChartSlice'
import linkedinReducer from './redux/linkedinSlice'
import newPostPaymentReducer from './redux/newPostPaymentSlice'
import searchReducer from './redux/searchSlice'
import largerComboPackReducer from './redux/largerComboPackSlice'
import timerReducer from './redux/timerSlice'
import dayTimerReducer from './redux/dayTimerSlice'
import addOnsReducer from './redux/addOnSlice'

const isDevelopment = ENV === 'DEVELOPMENT';

const encryptor = encryptTransform({
    secretKey: REK,
    onError: function (error) {
        // Handle the error.
        console.log(error)
    }
})

const persistConfig = {
    key: 'root',
    transforms: [encryptor],
    version: 1,
    storage
}

const reducer = combineReducers({
    authenticationDetails: authenticationReducer,
    mcquestions: mcqReducer,
    otherDetails: otherDetailsReducer,
    answers: answersReducer,
    multiSkillSelection: multiSkillSelectionReducer,
    personaliseCourseSyllabus: personaliseCourseSyllabusReducer,
    personalisedCourseDetail: personalisedCourseDetailsReducer,
    postPaymentDetails: postPaymentReducer,
    influencerDetails: influencersDataReducer,
    priceChart: priceChartReducer,
    linkedinDetails: linkedinReducer,
    newPostPaymentDetails: newPostPaymentReducer,
    searchResults: searchReducer,
    largerComboPacks: largerComboPackReducer,
    timer: timerReducer,
    dayTimer: dayTimerReducer,
    addOns: addOnsReducer

})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunkMiddleware],
    devTools: isDevelopment,
});

  
export default store