import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


export const getRelatedSkills = createAsyncThunk(
    'multiSkillSelection/sendMultiSkillSelectionDetails',
    async ({  subject_name, n }) => {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/similar_skills_list', { subject_name, n })
            return [ ...response.data?.similar_list ]
        } catch (err) {
            console.log(err)
            throw err
        }        
    }
)

const initialState = {
    skillList: []
}

// Redux slice for questions
const multiSkillSelectionSlice = createSlice({
    name: 'multiSkillSelection',
    initialState,
    reducers: {
        resetInitialState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getRelatedSkills.fulfilled, (state, action) => {
            state.skillList = action.payload
        })
    },
})

export const { resetInitialState } = multiSkillSelectionSlice.actions
export const selectRelatedSkills = (state) => state.multiSkillSelection.skillList
export default multiSkillSelectionSlice.reducer;