import React, { useEffect, useState } from 'react'
import { expertsDataBasedOnSubject } from '../../utilities/constants'
import useWindowSize from '../../hooks/useWindowSize'

const CocreatedBy = ({ skillName }) => {

    const [ expertsData, setExpertsData ] = useState([])

    const { width } = useWindowSize()

    useEffect(() => {
        if (expertsDataBasedOnSubject[skillName]){
            setExpertsData(expertsDataBasedOnSubject[skillName])
        }else{
            setExpertsData(expertsDataBasedOnSubject['Others'])
        }
    },[skillName])

    return (
        <div className={`flex justify-center ${width <= 360 ? 'gap-4' : 'gap-8'} items-center text-white mt-2`}>
            <div>
                <p className='text-[10px] font-medium'>Co-Created By</p>
                <p className='text-sm font-medium'>{expertsData?.[0]?.expert_name}</p>
            </div>
            <div className='flex gap-1 items-center mt-1'>
                <img src={expertsData?.[0]?.imgLink} width={40} height={40} alt="" className='w-10 h-10 rounded-full object-cover'/>
                <div>
                    <p className='text-[10px] mb-0.5'>{expertsData?.[0]?.designation} {expertsData?.[0]?.companyName}</p>
                    <img src={expertsData?.[0]?.companyImage} width={20} height={20} alt="" />
                </div>
            </div>
        </div>
    )
}

export default CocreatedBy