
const images = [
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2010460.svg?updatedAt=1729748467569',
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2010462.svg?updatedAt=1729748750556',
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2010463.svg?updatedAt=1729748750814',
]

const imagesTwo = [
    {
        link: 'https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%20579.svg?updatedAt=1730202937308',
        width: 66,
        height: 30
    },
    {
        link: 'https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%20580.png?updatedAt=1730203123844',
        width: 98.7,
        height: 28
    },
    {
        link: 'https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%20581.png?updatedAt=1730203123861',
        width: 63.7,
        height: 20
    }
]

const AwardWinning = () => {

    return (
        <div className='mt-10'>
            <h2 className='text-2xl md:text-[32px] font-semibold mb-3 text-left'>Award Winning Platform</h2>
            <div className='bg-secondary_background rounded-xl p-3 flex flex-col gap-2.5'>
                <div className={`grid gap-2 grid-cols-3`}>
                    {images.map((image, index) => (
                        <div key={index} className="w-full">
                            <img src={image} alt='' className="w-full h-auto" />
                        </div>
                    ))}
                </div>
                <div className='bg-bg-1 p-3 rounded-xl'>
                    <p className='text-center text-xs text-white/70 mb-2'>As seen on</p>
                    <div className='flex flex-col gap-6'>
                        <div className='w-full grid grid-cols-10 items-center gap-4'>
                            {imagesTwo?.map((item, index) => (
                                <img src={item.link} key={index} width={item.width} height={item.height} className={`${index === 0 || index === 2 ? 'col-span-3' : 'col-span-4'} w-full`} alt="" />
                            ))}
                        </div>
                        <div className='w-full grid grid-cols-10 items-center gap-4'>
                            <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/Logo.svg.png?updatedAt=1730203591190" className='col-span-3 w-full h-auto' alt="" />
                            <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%20582.png?updatedAt=1730203591233" className='col-span-7 w-full h-auto' alt="" />
                        </div>
                        <div className='w-full grid grid-cols-10 items-center gap-4'>
                            <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/the_times_of_india_logo.svg.png?updatedAt=1730203821676" className='col-span-7 w-full h-auto' alt="" />
                            <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/inc42_logo.svg.png?updatedAt=1730203821712" className='col-span-3 w-full h-auto' alt="" />
                        </div>
                    </div>
                </div>
                <img src="https://ik.imagekit.io/238r9emirjj/new-certificate-changes/Frame%2010494.svg?updatedAt=1732339824745" alt="" />
            </div>
        </div>
    )
}

export default AwardWinning