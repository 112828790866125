import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const firstCardDetails = [
    {
        imageLink: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(13).png?updatedAt=1715081992883',
        mainText: '7 Lac+',
        heading1: 'Jobs You',
        heading2: 'Can Apply'
    },
    {
        imageLink: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(14).png?updatedAt=1715082079503',
        mainText: '10 Lac+',
        heading1: 'Learners',
        heading2: 'Supported'
    },
    {
        imageLink: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(4).png?updatedAt=1709791376837',
        mainText: '42%',
        heading1: 'Increase in',
        heading2: "CV Value"
    },
]

const CertificateHelp = () => {

    const checkMark = <div className='w-4 h-4 md:w-6 md:h-6 bg-secondary-text flex items-center justify-center rounded-full'>
        <FontAwesomeIcon icon={faCheck} className='text-xs md:text-sm'/>
    </div>

    return (
        <div className='mt-6 md:mt-10'>
            <h2 className='text-2xl md:text-[32px] font-semibold mb-3 text-left'>How Our Certificates Help</h2>
            <div className='bg-secondary_background p-4 flex flex-col gap-2 md:p-5 rounded-xl text-left md:text-xl'>
                <div className='flex justify-between gap-2.5'>
                    {firstCardDetails?.map((item, index) => (
                        <div key={index} className='bg-bg-1 rounded-lg flex-1 flex flex-col items-center gap-2.5 p-2'>
                            <div className='w-fit h-8 flex items-center mx-auto'>
                                <img src={item.imageLink} width={32} height={43} alt="" className='w-auto h-full'/>
                            </div>
                            <h3 className='text-xl font-bold text-[#00C1FF] text-center leading-5 mt-1'>{item.mainText}</h3>
                            <div className='text-center leading-4 text-xs flex-1 flex flex-col justify-end'>
                                <p>{item.heading1}</p>
                                <p>{item.heading2}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='bg-bg-1 p-3 rounded-xl flex items-center gap-2'>
                    {checkMark}
                    <p className='flex-1 text-sm sm:text-base'>Put on LinkedIn for a <span className='font-semibold'>34% chance of employers </span>  directly reaching out</p>
                </div>
                <div className='bg-bg-1 p-3 rounded-xl flex items-center gap-2'>
                    {checkMark}
                    <p className='flex-1 text-sm sm:text-base'>Earn college credits & be different from <span className='font-semibold'>20 million college students</span></p>
                </div>
                <div className='bg-bg-1 p-3 rounded-xl flex items-center gap-2'>
                    {checkMark}
                    <p className='flex-1 text-sm sm:text-base'>Switch careers faster with a <span className='font-bold'>53% higher chance</span> of interviews</p>
                </div>
                <div className='bg-bg-1 p-3 rounded-xl flex gap-2'>
                    {checkMark}
                    <div className='flex-1 max-sm:-mt-0.5'>
                        <p className='text-sm sm:text-base'>Co-certified by top brands:</p>
                        <div className='flex items-center'>
                            <div className='w-[30%] h-full mr-2'>
                                <img src="https://ik.imagekit.io/238r9emirjj/About-us/Canva%20-%20png%200.png?updatedAt=1689922310091" loading='lazy' alt="" className='w-full h-auto'/>
                            </div>
                            <div className='w-[30%] h-full mr-2'>
                                <img src="https://ik.imagekit.io/238r9emirjj/About-us/Wix%20-%20png%200.png?updatedAt=1689922493594" loading='lazy' alt="" className='w-full h-auto'/>
                            </div>
                            <div className='w-[30%] h-full mr-2'>
                                <img src="https://ik.imagekit.io/238r9emirjj/About-us/Hootsuite%20-%20png%200.png?updatedAt=1689922577637" loading='lazy' alt="" className='w-full h-auto'/>
                            </div>
                            <div className='w-[30%] h-full'>
                                <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/Zoho_-_png_0.png?updatedAt=1688984009258" loading='lazy' alt="" className='w-full h-auto'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CertificateHelp