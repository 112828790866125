import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCertificateImage } from '../../redux/postPaymentSlice'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import GreenTickMark from './GreenTickMark'
import { selectRelatedJobs } from '../../redux/influencersDataSlice'

const coursePoints = [
    'Pre-Recorded, Learn At Your Pace',
    '24/7 Live Doubt Solving',
    'Certificate For Each Topic',
    'Personalised Path, 3X Faster Progress',
    'Tests with Real-Time Feedback'
]

const certificatePoints = [
    'Certify Skills In 10 Minutes',
    'Recognised By 1,100+ Companies',
    'Share On LinkedIn & CV',
    'Get 64% More Recruiter Replies'
]

const newCompanyImages = [
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%20340.svg?updatedAt=1727448593845',
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/dream11_icon.jpeg.svg?updatedAt=1727448593700',
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%20344.svg?updatedAt=1727448593901',
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/zepto_icon.jpeg.svg?updatedAt=1727448593913',
    'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Paytm%20-%20jpeg%200.svg?updatedAt=1727448593972',
]

const CertificateImageModalInside = ({ handleModal, setShowCertificateImageModalInside }) => {

    const certificateImage = useSelector(selectCertificateImage)
    const relatedJobs = useSelector(selectRelatedJobs)

    const courseImage = useMemo(() => certificateImage === 'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2022085%20(1).png?updatedAt=1729606706291', [certificateImage])

    const points = useMemo(() => courseImage ? coursePoints : certificatePoints, [courseImage]) 

    useEffect(() => {
        Mixpanel.track(courseImage ? constants.Mixpanel.COURSE_CERTIFICATE_MODAL_OPENED : constants.Mixpanel.CERTIFICATE_MODAL_OPENED)
    }, [courseImage])

    return (
        <div className='p-3'>
            <div className='flex gap-2 items-center justify-between mb-3'>
                {(setShowCertificateImageModalInside) && <div className='cursor-pointer' 
                    onClick={() =>{
                        setShowCertificateImageModalInside(false)   
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className='text-2xl'/>
                </div>}
                <h3 className='text-xl font-bold flex-1 mx-auto'>{courseImage ? 'Courses With Certificate Of Completion' : 'Certificate Of Assessment'}</h3>
                <div className='cursor-pointer' onClick={handleModal}>
                    <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
                </div>
            </div>
            <img src={certificateImage} alt="" />
            <h4 className='text-sm font-semibold mt-4 mb-2'>At The End Of 4 Weeks, We Get You-</h4>
            {courseImage ? <div className='flex flex-col gap-2'>
                <div className='certificate-modal-points'>
                    <GreenTickMark />
                    <div className='flex-1'>
                        <h3 className='text-xs mb-2 leading-[130%]'>Skills to Apply for Jobs like - </h3>
                        <div className='flex items-center flex-wrap gap-1.5'>
                            {relatedJobs?.map((item, index) => (
                                <div key={index} className='text-xs flex items-center bg-bg-1 rounded-lg px-2 py-1 gap-1'>
                                    <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/mdi_work-outline.svg?updatedAt=1727501489145" width={16} height={16} alt="" />
                                    <p>{item}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='certificate-modal-points'>
                    <GreenTickMark />
                    <div className='flex-1'>
                        <h3 className='text-xs mb-2'><span className='font-bold'>92%+ Chance</span> of Working At Top Brands</h3>
                        <div className='flex gap-1.5 items-center'>
                            {newCompanyImages.map((image, index) => (
                                <img key={index} width={28} height={28} src={image} alt="" />
                            ))}
                        </div>
                    </div>
                </div>
                <div className='certificate-modal-points'>
                    <GreenTickMark />
                    <h3 className='text-xs flex-1'>Eligible for Upto <span className='font-bold'>73% Higher Salary</span></h3>
                </div>
            </div> : <div className='flex flex-col gap-2'>
                <div className='flex gap-2 items-center p-3 bg-bg-2 rounded-xl'>
                    <GreenTickMark />
                    <h3 className='text-xs flex-1'>Improved CV & Higher Chance Of <span className='font-bold'>Employers Directly Reaching Out</span></h3>
                </div>
                <div className='flex gap-2 items-center p-3 bg-bg-2 rounded-xl'>
                    <GreenTickMark />
                    <h3 className='text-xs flex-1'><span className='font-bold'>63% </span>Better & Improved LinkedIn Profile</h3>
                </div>
            </div>}
            <div className='mt-4'>
                <h3 className='text-sm font-semibold'>{courseImage ? 'Why These Courses Matter?' : 'Why These Certificates Matter?'}</h3>
                <div className='flex flex-col gap-2 mt-2'>
                    {points?.map((item, index) => (
                        <div key={index} className='flex items-center gap-2 p-2 bg-bg-2 rounded-lg'>
                            <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/fluent_star-checkmark-20-regular.svg?updatedAt=1727506495596" width={16} height={16} alt="" />
                            <p className='flex-1 text-xs'>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CertificateImageModalInside