import { faCheck, faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from '@headlessui/react'
import React, { useState } from 'react'
import { selectPriceChart } from '../../redux/priceChartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import ReactPlayer from 'react-player'
import { selectShowPlacementModal, selectVideoDetails, selectVideoIndex, setShowPlacementModal, setVideoDetails } from '../../redux/otherDetailsSlice'
import { placementData } from '../../utilities/constants'
import { selectAddOns, setFourWeekPlacementFeature } from '../../redux/addOnSlice'

export const placementModalData  = [
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(3).png?updatedAt=1712666131067',
        title: 'Get your CV Revamped',
        description: 'Updated ATS friendly CV for 5x more interview calls'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/devicon_linkedin.png?updatedAt=1709878343522',
        title: 'LinkedIn Profile Upgrade',
        description: 'Users report get 63% more recruiter connects'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/Lead%20Generation/Vector%20(4).png?updatedAt=1712666881754',
        title: 'Interview Training',
        description: 'Increase your chance of cracking inteviews by 46%'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2077.png?updatedAt=1712660855640',
        title: '5 Practice Interviews',
        description: 'Pass tech rounds & negotiate for 56% higher CTC'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/Lead%20Generation/Vector%20(5).png?updatedAt=1712667124358',
        title: 'Get CV Sent to 1100+ hiring partners',
        description: '93% placement rate with top MNCs & startups'
    },
]

const FourWeekPlacementModal = () => {

    const priceChart = useSelector(selectPriceChart)
    const showPlacementModal = useSelector(selectShowPlacementModal)
    const videoIndex = useSelector(selectVideoIndex)
    const videoDetails = useSelector(selectVideoDetails)
    const addOns = useSelector(selectAddOns)

    const { fourWeekPlacementFeature } = addOns
    const title = videoDetails?.title
    const subtext = videoDetails?.subtext

    const [ showVideo, setShowVideo ] = useState(false)
    const [ showVideoUrl, setShowVideoUrl ] = useState('')

    const dispatch = useDispatch()

    const closeModal = () => {
        setShowVideo(false)
        dispatch(setShowPlacementModal(false))
    }

    return (
        <div>
            <Dialog
                open={showPlacementModal}
                onClose={closeModal}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-50 bg-black opacity-80" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className={`bg-custom-bg max-w-xl rounded-2xl w-[90%] mx-auto my-auto z-[80] relative max-h-[650px] md:max-h-[550px] overflow-y-scroll p-3 border`}
                    style={{
                        boxShadow: '0px 0px 20px 0px rgba(8, 103, 136, 0.15)'
                    }}
                >
                    <div className='flex gap-2 items-start justify-between'>
                        {showVideo && <FontAwesomeIcon icon={faChevronLeft} className='text-2xl cursor-pointer mt-0.5' onClick={() => setShowVideo(false)} />}
                        <h2 className='flex-1 text-xl font-bold'>Join our 4 Week Placement Training & Get Hired 3X Faster</h2>
                        <FontAwesomeIcon icon={faXmark} className='text-3xl cursor-pointer' onClick={closeModal} />
                    </div>
                    {!showVideo ? <>
                        <div className='flex flex-col gap-2 mt-4'>
                            {placementModalData?.map((item, index) => {
                                const selectedVideo = index === videoIndex
                                return (<div key={index} className={`${selectedVideo ? 'bg-bg-9 p-3 border border-secondary-text' : 'bg-bg-2 px-3 py-2'} rounded-xl flex items-center gap-3 cursor-pointer`} onClick={() => {
                                    setShowVideo(true)
                                    dispatch(setVideoDetails({
                                        url: item?.video_link,
                                        title: item?.title,
                                        subtext: item?.description,
                                        thumbnail: item?.thumbnail
                                    }))
                                    setShowVideoUrl(placementData?.[index < 4 ? index : 3]?.video_link)
                                }}>
                                    <div className={`${selectedVideo ? 'w-[100px]' : 'w-[81px]'} h-full relative`}>
                                        <img src={placementData?.[index < 4 ? index : 3]?.thumbnail} width={100} height={68.8} className='w-full h-auto' alt="" />
                                        <FontAwesomeIcon icon={faPlayCircle} className='absolute inset-0 m-auto'/>
                                    </div>
                                    <div className='flex-1'>
                                        <h3 className={`${selectedVideo ? 'text-base' : 'text-sm'} font-semibold leading-[130%] mb-0.5 line-clamp-1`}>{item.title}</h3>
                                        <p className={`${selectedVideo ? 'text-sm text-white/70 leading-[140%]' : 'text-xs text-white/50'} mt-1`}>{item.description}</p>
                                    </div>
                                </div>
                            )})}
                        </div>
                    </> : <div className='bg-bg-2 p-3 max-w-xl rounded-2xl mx-auto my-auto z-50 w-full relative mt-4'>
                        <ReactPlayer
                            url={showVideoUrl}
                            config={{
                            file: {
                                attributes: {
                                preload: 'auto',
                                },
                            },
                            youtube: {
                                playerVars: { modestbranding: 1, rel: 0 },
                            },
                            }}
                            width={"100%"}
                            height={
                            window.innerWidth > 768
                                ? window.innerWidth * (9 / 16) * (5 / 12)
                                : window.innerWidth * (9 / 16)
                            }
                            controls={true}
                            playing={true}
                        />
                        <div className='flex gap-2 items-start mt-2'>
                            <div className='mt-[3px] bg-secondary-text w-4 h-4 flex items-center justify-center rounded-full'>
                                <FontAwesomeIcon icon={faCheck} className='text-sm' />
                            </div>
                            <div className='flex-1'>
                                <h3 className='font-semibold'>{title}</h3>
                                <p className='text-sm text-white/50 mt-0.5'>{subtext}</p>
                            </div>
                        </div>
                    </div>}
                    <div className='flex justify-between items-center mt-6'>
                        <p className=''><span className='text-[#80C242] text-lg font-semibold'>₹{priceChart?.[34]?.price}</span> <span className='text-[#FF4646] font-semibold'>₹</span><span className='text-[#FF4646] line-through'>{priceChart?.[34]?.original_price}</span></p>
                        <div className='w-1/2 bg-shadow text-center py-3 text-black text-sm font-medium rounded-lg cursor-pointer' onClick={() => {
                            if (fourWeekPlacementFeature){
                                toast.success("Already In Cart")
                            }else{
                                dispatch(setFourWeekPlacementFeature(true))
                                toast.success("Added to Cart")
                            }
                        }}>
                            Add To Cart
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default FourWeekPlacementModal